import React, { useEffect, useState } from "react";

import "./styles.css";
const Preloader = () => {
	const [showPreloader, setShowPreloader] = useState(true);

	useEffect(() => {
		const preloaderTimeout = setTimeout(() => {
			console.log(showPreloader);
			setShowPreloader(false);
		}, 2000);

		return () => clearTimeout(preloaderTimeout);
	}, []);
	return (
		<>
			{console.log(showPreloader)}
			{showPreloader && (
				<div className="preloader-wrap">
					<div class="preloader">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			)}
		</>
	);
};

export default Preloader;
