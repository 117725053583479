import React from "react";
import "./styles/main.scss";
import "remixicon/fonts/remixicon.css";
import Banner from "./banner";
import Preloader from "./preloader";

const App = () => {
	return (
		<>
			<Preloader />
			<Banner />
		</>
	);
};

export default App;
