export const bannerImages = [
  {
    image: "/images/banner-img-1.png",
  },
  {
    image: "/images/banner-img-2.png",
  },
  {
    image: "/images/banner-img-3.png",
  },
  {
    image: "/images/banner-img-4.png",
  },
];
